import { serialize } from 'cookie';
import { cookieDomain } from '../config';

const TOKEN_NAME = 'api_token';
const MAX_AGE = 60 * 60 * 24 * 7;

function createCookie(name, data, options = {}) {
  return serialize(name, data, {
    maxAge: MAX_AGE,
    expires: new Date(Date.now() + MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    httpOnly: true,
    sameSite: 'lax',
    ...options,
  });
}

function createDeleteCookie(name, data, options = {}) {
  return serialize(name, data, {
    maxAge: 1,
    expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    httpOnly: true,
    sameSite: 'lax',
    ...options,
  });
}

function setTokenCookie(res, token) {
  res.setHeader('Set-Cookie', [
    createCookie(TOKEN_NAME, token),
    createCookie('authed', true, { httpOnly: false }),
  ]);
}

function setDeleteCookies(res) {
  res.setHeader('Set-Cookie', [
    createDeleteCookie(TOKEN_NAME, ''),
    createDeleteCookie('authed', false, { httpOnly: false }),
  ]);
}

function getAuthToken(cookies) {
  return cookies[TOKEN_NAME];
}

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export default {
  setTokenCookie,
  getAuthToken,
  setDeleteCookies,
  getCookie,
};
