export const isDev = process.env.NODE_ENV === 'development';
export const serverURL = isDev
  ? 'http://localhost:3000'
  : 'https://www.startscreen.io';
export const cookieDomain = isDev
  ? 'localhost'
  : '.startscreen.io';
export const isChromeExtension =
  typeof window !== 'undefined' &&
  window.chrome &&
  chrome.runtime &&
  chrome.runtime.id;
