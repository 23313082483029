// hooks/useAuth.js
import React from 'react';
import useSWR from 'swr';
// import * as Sentry from '@sentry/nextjs';
// import mixpanel from '../../lib/mixpanel';

const fetcher = (route) => {
  return fetch(route)
    .then((r) => {
      if (!r.ok) return Promise.reject(r.status);
      return r.ok && r.json();
    })
    .then((res) => {
      return res || null;
    });
};

const useNewAuth = (optionalCallback) => {
  const { data, error, mutate } = useSWR(`/api/auth/user`, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  // set up mixpanel and sentry
  // if (data && data.user) {
  // Sentry.setUser({ email: data.user.email, id: data.user._id });
  // mixpanel.register({
  //   id: data.user._id,
  //   email: data.user.email,
  // });
  // }
  if (error) {
    return { data: null, loading, error };
  }

  const loading = data === undefined && error === undefined;

  const fullRes = {
    data,
    loading,
    error,
  };

  if (optionalCallback) optionalCallback(fullRes);
  return fullRes;
};

export default useNewAuth;
